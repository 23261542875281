export default class BalanceDownTableItem {
    balanceId = 0;
    balanceStatus = 0;
    userCode = "";
    userName = "";
    balanceValue = 0;
    balanceBatch = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
    batchId = 0;
    batchStatus = 0;
    batchName = "";
    batchNotes = "";
}
