import { Options } from "vue-class-component";
import { useToast } from "vue-toastification";
const toast = useToast();
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import BalanceDownTableItem from "@/entity/attendance/duty/BalanceDownTableItem";
import API from "@/api/attendance/duty/balanceDown";
import BatchAPI from "@/api/attendance/duty/batch";
import { IRuntimePermissionRequestAuthCodeParams } from "dingtalk-jsapi/api/runtime/permission/requestAuthCode";
import * as dd from "dingtalk-jsapi";
import { DingAK } from "@/utils/axios";

@Options({
    name: "app-attendance-duty-balanceDown",
    components: {},
})
export default class BalanceDown extends BaseTableMixins<BalanceDownTableItem> implements Partial<BaseTableInterface<BalanceDownTableItem>> {
    private curBatchId = 0;
    protected batchState: any = {};
    private isShow = false;

    public created(): void {
        this.setInterface(this);
        this.editTableItem = new BalanceDownTableItem();
        this.editModalItem = new BalanceDownTableItem();
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //获取批次状态
    protected getBatch() {
        BatchAPI.getBatchOne({})
            .then((res) => {
                this.batchState = res.data.records.batchState;
                this.curBatchId = res.data.records.batchId;
                console.log(this.curBatchId);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    //获取数据
    public activated(): void {
        this.getList(1);
        this.getBatch();
    }

    //添加请求字段
    public requestItem(): any {
        return {
            batchId: this.curBatchId,
        };
    }

    //同步批次
    get batchIdSelectApi() {
        return {
            api: (query: any) => BatchAPI.idSelect(query),
            status: "batch_status",
            name: "batch_name",
            id: "batch_id",
            cid: this.curBatchId,
        };
    }
    private updateBatchID() {
        this.getList(0);
    }

    //下载余额
    private btnDownloadBalance(): void {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("dingtalk") !== -1) {
            dd.ready(() => {
                this.isLoading = true;
                dd.runtime.permission.requestAuthCode(<IRuntimePermissionRequestAuthCodeParams>{
                    corpId: DingAK,
                    onSuccess: (result: any) => {
                        const authCode = result.code;
                        // alert(JSON.stringify(result));
                        // 在这里处理获取到的 authCode
                        API.add({ authCode })
                            .then(() => {
                                this.getList(0);
                            })
                            .finally(() => {
                                this.isLoading = false;
                            });
                    },
                    onFail: (err: any) => {
                        // 处理授权失败的情况
                        toast.error("获取失败" + err);
                        this.isLoading = false;
                    },
                });
            });
        } else {
            this.isLoading = false;
            toast.error("请从钉钉APP内打开");
            return;
        }
    }

    //更新全部假期数据
    private btnUpdateAllData(): void {
        this.sendRequest(this.tableItems);
    }
    //更新全部假期数据请求后端接口
    private sendRequest(data: any) {
        this.isLoading = true;
        this.isShow = true;
        API.dataBasicUpload({ data }).then((res: any) => {
            toast.success(res.msg);
            //每1s请求一次直到batchState等于30
            const timer = setInterval(() => {
                BatchAPI.getBatchOne({}).then((response: any) => {
                    const batchState = response.data.records.batchState;
                    // 判断是否为30
                    if (batchState === 30) {
                        clearInterval(timer);
                        this.isLoading = false;
                        this.isShow = false;
                        toast.success(response.msg);
                        this.getList(0);
                    }
                });
            }, 1000);
        });
    }
}
